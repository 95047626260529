th {
    min-width: 100px;
    vertical-align: bottom !important
}

td {
    min-width: 100px
}

button.report-link {
    display: block;
    margin-bottom: 10px;
    cursor: pointer !important;
    width: 200px;
    text-align: center;
    background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0)) !important;
    color: #182026 !important;
}

th:hover {
    cursor: pointer;
}

@media print {
    th {
        text-align: left;
    }
    td {
        text-align: left;
    }
}

 